/* global require, up */

const els = (sel, par) => (par || document).querySelectorAll(sel);

up.compiler("#otp", function(element){
  els("#otp").forEach((elGroup) => {
    const elsInput = [...elGroup.children];
    const len = elsInput.length;

    const handlePaste = (ev) => {
      const clip = ev.clipboardData.getData("text");     // Get clipboard data
      const pin = clip.replace(/\s/g, "");               // Sanitize string
      const ch = [...pin];                               // Create array of chars
      elsInput.forEach((el, i) => el.value = ch[i]??""); // Populate inputs
      if (elsInput[pin.length - 1]?.focus){
        elsInput[pin.length - 1].focus();                // Focus input
      }
    };

    const handleKeyDn = (ev) => {
      const input_field = ev.currentTarget
      const i = elsInput.indexOf(input_field);
      const current_text = input_field.value
      const user_input = ev.key
      const valid = user_input.match(/[0-9]/);
      if (valid) {
        input_field.value = user_input
        if ((i+1) % len){   // focus next
          elsInput[i + 1].focus();
        }
      }
      if (!current_text && ev.key === "Backspace" && i) {
        elsInput[i - 1].focus(); // Focus previous
      }
    };

    // Add the same events to every input in group:
    elsInput.forEach(elInp => {
      elInp.addEventListener("paste",   handlePaste);   // Handle pasting
      elInp.addEventListener("keydown", handleKeyDn); // Handle deleting
    });

    return function (){
      elsInput.forEach(elInp => {
        elInp.removeEventListener("paste",   handlePaste);   // Handle pasting
        elInp.removeEventListener("keydown", handleKeyDn); // Handle deleting
      });
    }
  });

})
