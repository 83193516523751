/* global performance, up, module */
// used for cleaning up event listeners
function invoke(f){f()};

Array.prototype.shuffle = function() {
  var i = this.length, j, temp;
  if ( i == 0 ) return this;
  while ( --i ) {
    j = Math.floor( Math.random() * ( i + 1 ) );
    temp = this[i];
    this[i] = this[j];
    this[j] = temp;
  }
  return this;
}

// Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you"d like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.

function throttle(func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) options = {};
  var later = function() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function() {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
};

function djb2Hash(str) {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = (hash * 33) ^ str.charCodeAt(i);
  }
  return hash >>> 0;  // Ensure the hash is a positive integer
}

function timer(label, func) {
  const start = performance.now();
  func();
  const end = performance.now();
  const elapsedTime = end - start;
  console.log(`${label}: Function took ${elapsedTime} milliseconds to execute.`);
}

function get_key_paths(obj, parentKeys = []) {
  let keyPaths = [];

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const currentKeys = parentKeys.concat(key);

      if (up.util.isObject(value)) {
        // Recursively call the function for nested objects
        const nestedKeyPaths = get_key_paths(value, currentKeys);
        keyPaths = keyPaths.concat(nestedKeyPaths);
      }
      else {
        keyPaths.push(currentKeys);
      }
    }
  }

  return keyPaths.map(up.util.flatten);
}

function get_in(obj, key_path) {
  //console.log("get_in", obj, key_path)

  let value = obj;

  for (let i = 0; i < key_path.length; i++) {
    const key = key_path[i];
    const value_lookup = value[key]
    if (up.util.isGiven(value_lookup)) {
      value = value_lookup;
    } else {
      // Key does not exist in the object
      console.log("key doesn't exist in obj", key, key_path, obj)
      return undefined;
    }
  }

  return value;
}

// prefix should be string, path should be array of keys for an object
function keypath_to_form_field(path, prefix = ""){
  return path.reduce(
    (acc, key) => {
      return acc += `[${key}]`
    },
    prefix)
}

function flatten_obj(obj){
  const path_val_pairs = get_key_paths(obj);
  return path_val_pairs.map((path) => {
    const value = get_in(obj, path)
    return [path, value]
  });
}

module.exports = {
  djb2Hash,
  flatten_obj,
  get_in,
  get_key_paths,
  invoke,
  keypath_to_form_field,
  throttle,
  timer,
}
