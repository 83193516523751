/* global require, up */

function render_template(element, target_template){
  const node_to_insert = target_template.content.cloneNode(true);
  console.log("render template", node_to_insert)
  element.appendChild(node_to_insert)
}

up.compiler("[nv-hide-for]", function(element, data){
  const event_listening_on = up.element.attr(element, "respond-to")
  const template_id        = up.element.attr(element, "template")
  const template           = element.querySelector("template")
  const hide_value         = up.element.attr(element, "nv-hide-for")

  if(template && hide_value && event_listening_on){}
  else {
    console.group()
    console.error("[nv-hide-for] missing attributes")
    console.dir({
      element: element,
      template: template,
      event_listening_on: event_listening_on,
      hide_value:  hide_value
    })
    console.groupEnd()
    return
  }

  return up.on(event_listening_on, function(e){
    element.innerHTML = ""
    if(e.value !== hide_value){
      render_template(element, template)
    }
  })
})

up.compiler("[nv-show-for]", function(element, data){
  const event_listening_on = up.element.attr(element, "respond-to")
  const template_id        = up.element.attr(element, "template")
  const template           = element.querySelector("template")
  const show_value         = up.element.attr(element, "nv-show-for")

  if(template && show_value && event_listening_on){}
  else {
    console.group()
    console.error("[nv-hide-for] missing attributes")
    console.dir({
      element: element,
      template: template,
      event_listening_on: event_listening_on,
      show_value:  show_value
    })
    console.groupEnd()
    return
  }

  return up.on(event_listening_on, function(e){
    element.innerHTML = ""
    if(e.value === show_value){
      render_template(element, template)
    }
  })
})
