/* global require, up */

// unpoly already has a up-emit for <a> so `*:not(a)` avoids those
up.compiler("*:not(a)[up-emit]", function(element){
  const event_name =  up.element.attr(element, "up-emit")
  const event_props = up.element.jsonAttr(element, "up-emit-props") ?? {}
  if(event_name){
    return up.on(element, "up:click", function(event){
      up.emit(element, event_name, event_props)
    })
  }
})

up.compiler("[emit-on-change]", function(element, data){
  const event_name = up.element.attr(element, "emit-on-change")
  return up.on(element, "change", function(e){
    up.emit(event_name, {value: element.value});
  })
})

up.compiler("[emit-on-load]", {priority: -10}, function(element, data){
  const event_name = up.element.attr(element, "emit-on-load")
  up.emit(event_name, {value: element.value});
})
